<template>
    <chooser
        title='בחירת שאלות חדשות למעקב'
        @submit='submit'
        submitText='המשך'
        width='48vw'
        allowLegends
    />
</template>

<script lang='ts'>
import { QuestionnairePayload } from '@/ts/interfaces/Questionnaire';
import { defineComponent } from '@vue/runtime-core';
import { getSelected } from '@/SharedChart/Chooser/helpers';
import { getCommitId } from './helpers';

export default defineComponent({
    async beforeMount(){
        const { questionnaireId } = this.$route.params;
        const { questionnaires } = this.$store.state.patient!;
        let questionnaire = questionnaires.length > 0 && questionnaires.find(q => q.id === questionnaireId)
        if(!questionnaire){
            const fetched = await this.$request(`/questionnaire/${questionnaireId}`);
            questionnaire = fetched.body;
            this.$store.commit('patient/setQuestionnaire', questionnaire);
        }else{
            const { questions, legends } = questionnaire;
            this.$store.commit('chooser/setSelected', { selected: questions, legends });
        }
    },
    methods: {
        async submit() {
            const questions = getSelected();
            if(!questions)
                return;

            const questionnaireId = this.$route.params.questionnaireId as string;
            const payload: QuestionnairePayload = { questions, legends: this.$store.state.chooser!.legends };
            const commitId = getCommitId(questionnaireId);
            if(commitId)
                payload.commit_id = commitId;

            try{
                const { body: updated } = await this.$request(`/questionnaire/${questionnaireId}`, 'put', payload);
                this.$store.commit('patient/setQuestionnaire', updated);
                this.$store.commit('patient/addCommit', updated.commit_id);

                const { patientId } = this.$route.params;
                this.$router.push(`/${patientId}/questionnaires`);
            }catch(error){
                console.error(error);
            }
        },
    }
});
</script>